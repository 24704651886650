import React from "react"
import Container from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Section from "../../styles/styled-component/Section"
import { useState } from "react"
import {
  TabContainer,
  TabItem,
  TabMenu,
  Tab,
} from "../specifications/specificationsElements"
import { SubmitButton, TextContainer } from "./contactElements"
import "./contact.css"

import { Trans } from "gatsby-plugin-react-i18next"

const Contact = () => {
  const [feedbackText, setFeedbackText] = useState("")

  return (
    <Section bgColor="#f7f8fc">
      <Container flex center direction="column">
        <InnerContainer centerText>
          <TextContainer>
            <p className="label">
              <Trans>Get in touch</Trans>
            </p>
            <h1>
              <Trans>Quote for your homelift</Trans>
            </h1>
            <p>
              <Trans>
                We are ready to help you, fill the form below to get in touch or
                receive a quote for your project
              </Trans>
            </p>
          </TextContainer>
        </InnerContainer>
      </Container>
      <Container flex center direction="column" bgColor="#fff" smallMaxWidth>
        <InnerContainer>
          <form
            name="divas contact"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            className="form"
          >
            <div>
              <input type="hidden" name="form-name" value="divas contact" />
              <p style={{ display: "none" }}>
                <label>
                  Don’t fill this out if you expect to hear from us!
                  <input name="bot-field" value="" readOnly />
                </label>
              </p>
              <label for="name" className="label-text">
                <Trans>Name</Trans>
              </label>
              <input
                className="input"
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                label
                required
              ></input>
            </div>
            <div>
              <p className="label-text">
                <Trans>Company / Website</Trans>
              </p>
              <input
                className="input"
                name="company"
                placeholder="Company / Website"
              ></input>
            </div>
            <div>
              <label for="email" className="label-text">
                <Trans>Email</Trans>
              </label>
              <input
                className="input"
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                required
              ></input>
            </div>
            <div>
              <label for="phone" className="label-text">
                <Trans>Phone</Trans>
              </label>
              <input
                className="input"
                id="phone"
                name="phone"
                placeholder="+XX 123 12 12 123"
              ></input>
            </div>
            <div>
              <label for="message" className="label-text">
                <Trans>Message</Trans>
              </label>
              <textarea
                className="input"
                name="message"
                id="message"
                placeholder="Message"
              ></textarea>
            </div>
            <SubmitButton value="send" type="submit">
              <Trans>Send</Trans>
            </SubmitButton>
            {<div>{feedbackText}</div>}
          </form>
        </InnerContainer>
      </Container>
    </Section>
  )
}

export default Contact
