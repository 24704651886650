import styled from "styled-components"
import { Link } from "gatsby"
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai"
export const TabContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  background-color: #f7f8fc;
  border-radius: 50px;
`
export const TabMenu = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 4px;
`
export const TabItem = styled.li`
  margin: 0 auto;
`
export const Tab = styled.button`
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 1.4rem;
  font-family: sans-serif;
  cursor: pointer;
  color: ${props => (props.isSelected ? "#1a5090" : "#0c0c0")};
  background-color: ${props => (props.isSelected ? "white" : "transparent")};
  transition: 0.2s;
  padding: 16px 30px;
  border-radius: 50px;
  text-align: center;
  box-shadow: ${props =>
    props.isSelected
      ? "2px 2px 3px 0 rgb(140 135 166 / 12%), 3px 7px 12px 0 rgb(140 135 166 / 8%), 1px 14px 24px 0 rgb(140 135 166 / 8%)"
      : "none"};
  &:hover {
    background-color: white;
    box-shadow: none;
  }
  font-weight: 600;
`
export const SpecsWrapper = styled.div`
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  display: ${({ isSelected }) => (isSelected ? "flex" : "none")};
`

export const SpecsList = styled.ul`
  width: 100%;
`
export const SpecsTitle = styled.li`
  font-weight: ${props => (props.title ? "700" : "500")};
  font-family: sans-serif;
  text-align: ${props => (props.title ? "left" : "right")};
`
export const SpecsDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`
export const SpecsValue = styled.li`
  font-weight: 500;
  font-family: sans-serif;
`
export const FaqWrapper = styled.div`
  width: 100%;
  padding: 1rem;
  border-radius: 50px;
  background-color: #f7f8fc;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  box-shadow: 2px 2px 3px 0 rgb(140 135 166 / 12%),
    3px 7px 12px 0 rgb(140 135 166 / 8%), 1px 14px 24px 0 rgb(140 135 166 / 8%);
`
export const FaqTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: start;
  padding: 1rem;
  border-bottom: ${({ isOpen }) => (isOpen ? "1px solid #9a9a9a" : "none")};
`
export const FaqTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;
`
export const PlusIcon = styled(AiOutlinePlus)`
  cursor: pointer;
  font-size: 1.5rem;
  @media screen and (max-width: 425px) {
    font-size: 2.5rem;
  }
`
export const MinusIcon = styled(AiOutlineMinus)`
  cursor: pointer;
  font-size: 1.5rem;
  @media screen and (max-width: 425px) {
    font-size: 2.5rem;
  }
`

export const FaqAnswer = styled.div`
  margin-top: 1rem;
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  padding: 1rem;
  align-items: center;
  width: 100%;
  background-color: #f7f8fc;
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
  text-align: left;
`

export const LinkText = styled(Link)`
  text-decoration: underline;
  color: #000;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-3px);
  }
`
export const SpecsContainer = styled.div`
  padding: 1rem 0;
`
