import styled from "styled-components"

export const SubmitButton = styled.button`
  padding: 15px 30px;
  border-radius: 50px;
  color: white;
  background-color: #1a5090;
  border: none;
  font-size: 24px;
  font-weight: 600;
  box-shadow: 2px 2px 3px 0 rgb(140 135 166 / 12%),
    3px 7px 12px 0 rgb(140 135 166 / 8%), 1px 14px 24px 0 rgb(140 135 166 / 8%);
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: #041c2c;
  }
  font-family: sans-serif;
`
export const TextContainer = styled.div`
  text-align: center;
`
