import React from "react"
import Contact from "../components/contact"
import { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contact us" />

      <Contact />
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
